import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPopups } from '../selectors';

import { createSpinnerPopup, createNewFeaturePopup } from '../actions/popup';
import { createAddCollection } from '../actions/collection';

import Header from './Header';
import MainSection from './MainSection';

import ErrorBoundary from '../components/ErrorBoundary';
import Overlay from '../components/Overlay';
import CollectionsPage from '../components/CollectionsPage';

import { oauth } from '../utils';

class CollectionsApp extends Component {

  componentDidMount() {
    const { user_id, onCreateNewFeaturePopup } = this.props;

    oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
      if(json.show_popup == 1) {
        onCreateNewFeaturePopup(json.popup_html);
      }
    });
  }

  render() {
    const { identity, popups, onAddCollection } = this.props;
    const hasCapability = capability => identity.capabilities.includes(capability);

    return (
      <ErrorBoundary>
        <Header>
          <div className="columns">
            <h4 style={{ display: 'inline-block' }}>Product Collections</h4>
            {hasCapability('CREATE-COLLECTION') ?
              <a className="button" style={{ float: 'right' }} onClick={e => onAddCollection()}>Create Collection</a> :
              null}
          </div>
        </Header>
        <MainSection popups={popups}>
          <CollectionsPage identity={identity} />
        </MainSection>
        <Overlay popups={popups} />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  popups: getPopups(state),
  user_id: state.identity.user_id
});

const mapDispatchToProps = dispatch => ({
  onAddCollection: () => Promise.resolve(dispatch(createSpinnerPopup('Creating Collection...'))).then(
    () => dispatch(createAddCollection())
  ),
  onCreateNewFeaturePopup: () => {
    dispatch(createNewFeaturePopup());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsApp);
